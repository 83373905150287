'use client';
import { useContext, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { InventoryTypeBadge } from '@/components/Inventory/Badges/InventoryTypeBadge';
import { defaultImageUrl } from '@/constants/inventory';
import { InventoryContext } from '@/contexts/InventoryContext';
import { LayoutContext } from '@/contexts/LayoutContext';
import { getFavoritesChannel } from '@/tools/broadcast';
import { getImageUrl, getListPriceLabel, isNewUnit } from '@/tools/inventory';
import { IMessageFavorites, MessageFavoritesType } from '@/types/inventory';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { faHashtag, faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonType } from '@lamesarv-sdk/components';
import {
  getFacetCode,
  getInventoryImage,
  getInventoryPageUrl,
  getMinimalInventory,
  navigate,
} from '@lamesarv-sdk/tools';
import { IBasicInventory, IInventory, InventoryField } from '@lamesarv-sdk/types';

import { FavoriteBadge } from '../Inventory/Badges/FavoriteBadge';
import { FlashSaleBadge } from '../Inventory/Badges/FlashSaleBadge';
import { SpecialOfferBadge } from '../Inventory/Badges/SpecialOfferBadge';

const IMAGE_WIDTH = 340;
const IMAGE_HEIGHT = 285;

export interface IInventoryItemProps {
  item: IBasicInventory;
  customImage?: string;
  sold?: boolean;
  className?: string;
}

const formatPrice = (price: number) => Math.round(price).toLocaleString(undefined, { maximumFractionDigits: 0 });

const UsedPricing = ({ item }: { item: IBasicInventory }) => {
  return (
    <>
      {!!item[InventoryField.priceList] && (
        <div className="flex gap-2">
          <span className="text-base uppercase text-gray-500" data-testid="unit-list-label">
            {getListPriceLabel(item)}:
          </span>
          <span className="text-base font-bold line-through" data-testid="unit-list-price">
            ${formatPrice(item[InventoryField.priceList])}
          </span>
        </div>
      )}
      {!!item[InventoryField.priceSale] && (
        <div className="flex gap-2">
          <span className="text-base uppercase text-gray-500" data-testid="unit-sale-label">
            SALE PRICE:
          </span>
          <span className="text-base font-bold text-red-500" data-testid="unit-sale-price">
            ${formatPrice(item[InventoryField.priceSale])}
          </span>
        </div>
      )}
      {!!item[InventoryField.priceSale] && item[InventoryField.priceSale] !== item[InventoryField.priceList] && (
        <div className="flex gap-2">
          <span className="text-base uppercase text-gray-500" data-testid="unit-save-label">
            YOU SAVE:
          </span>
          <span className="text-base font-bold text-[#76881D]" data-testid="unit-save-price">
            ${formatPrice(item[InventoryField.priceList] - item[InventoryField.priceSale])}
          </span>
        </div>
      )}
    </>
  );
};

const NewPricing = ({ item }: { item: IBasicInventory }) => {
  const { context: layoutContext } = useContext(LayoutContext);

  const displayOnWeb = !!item[InventoryField.priceSale] && !!item[InventoryField.priceList];

  const renderedSalePrice = item[InventoryField.isTooLowToShow]
    ? item[InventoryField.priceWas]
    : item[InventoryField.websitePrice];

  return (
    <>
      {!!item[InventoryField.priceList] && (
        <div className="flex gap-2">
          <span className="text-base uppercase text-gray-500" data-testid="unit-list-label">
            {getListPriceLabel(item)}:
          </span>
          <span className="text-base font-bold line-through" data-testid="unit-list-price">
            ${formatPrice(item[InventoryField.priceList])}
          </span>
        </div>
      )}

      {!!renderedSalePrice && displayOnWeb && (
        <div className="flex gap-2">
          <span className="text-base uppercase text-gray-500" data-testid="unit-sale-label">
            SALE PRICE:
          </span>
          <span
            className={twMerge(
              'text-base font-bold text-red-500',
              item[InventoryField.isTooLowToShow] && 'line-through',
            )}
            data-testid="unit-sale-price"
          >
            ${formatPrice(renderedSalePrice)}
          </span>
        </div>
      )}

      {!!item[InventoryField.priceList] &&
        !!renderedSalePrice &&
        !item[InventoryField.isTooLowToShow] &&
        displayOnWeb && (
          <div className="flex gap-2">
            <span className="text-base uppercase text-gray-500" data-testid="unit-save-label">
              YOU SAVE:
            </span>
            <span className="text-base font-bold text-[#76881D]" data-testid="unit-save-price">
              ${formatPrice(item[InventoryField.priceList] - renderedSalePrice)}
            </span>
          </div>
        )}

      {(displayOnWeb ? item[InventoryField.isTooLowToShow] : !!item[InventoryField.priceList]) && (
        <span
          className="self-start mt-2 cursor-pointer rounded-md bg-gradient-to-b from-blue-500 to-blue-700 px-4 py-2 text-center text-sm font-bold uppercase text-white text-opacity-90 shadow-sm hover:from-blue-900 hover:to-blue-600 hover:shadow-md"
          onClick={() => navigate(getInventoryPageUrl(item) + '#pdpform')}
          dangerouslySetInnerHTML={{ __html: layoutContext.noPriceButtonContent || '' }}
        />
      )}
    </>
  );
};

export const InventoryItem = ({ item, customImage, sold, className }: IInventoryItemProps) => {
  const { context, favoriteSelectInventory, favoriteUnselectInventory } = useContext(InventoryContext);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const inventoryImage = useMemo(() => {
    if (customImage) return customImage;
    if (item[InventoryField.imageCloudinaryIds]?.length) {
      return getImageUrl(item[InventoryField.imageCloudinaryIds][0], IMAGE_WIDTH, IMAGE_HEIGHT);
    }
    return getInventoryImage({ item, defaultImageUrl });
  }, [item, defaultImageUrl]);

  const handleFavorite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const channelFavorites = getFavoritesChannel();
    if (e.target.checked) {
      const minimalItem = getMinimalInventory(item as IInventory, defaultImageUrl, inventoryImage);
      const newFavorites = favoriteSelectInventory(minimalItem, true);
      channelFavorites.postMessage({
        type: MessageFavoritesType.favoriteSelectInventory,
        id: item[InventoryField.id],
        quantity: newFavorites.length,
      } as IMessageFavorites);
    } else {
      const newFavorites = favoriteUnselectInventory(item[InventoryField.id], true);
      channelFavorites.postMessage({
        type: MessageFavoritesType.favoriteUnselectInventory,
        id: item[InventoryField.id],
        quantity: newFavorites.length,
      } as IMessageFavorites);
    }
  };

  const handleSimilarUnits = () => {
    navigate(`/search?model=${getFacetCode(item[InventoryField.model])}`);
  };

  useEffect(() => {
    const channel = getFavoritesChannel();

    channel.onmessage = (message: IMessageFavorites) => {
      if (message.id === item[InventoryField.id]) {
        setIsFavorite(message.type === MessageFavoritesType.favoriteSelectInventory);
      }
    };

    return () => {
      channel.close();
    };
  }, [item]);

  useEffect(() => {
    setIsFavorite(!!context?.inventoryFavorites?.find((favorite) => favorite.id === item[InventoryField.id]));
  }, [context.inventoryFavorites, item]);

  return (
    <div className={twMerge('group mx-2.5 pb-2 h-full', className)} data-testid="inventory-unit">
      <a href={sold ? undefined : getInventoryPageUrl(item)} className="flex flex-col h-full shadow-md">
        <div className="relative">
          <img
            src={inventoryImage}
            alt={item[InventoryField.title]}
            className="w-full object-cover group-hover:opacity-90"
          />
          {sold && (
            <div className="absolute inset-0 bg-neutral-400 bg-opacity-70">
              <div className="absolute w-full top-1/2 -translate-y-1/2">
                <div className="py-1 mb-2 bg-neutral-400 bg-opacity-70 sm:text-md text-center text-white font-bold uppercase">
                  Currently not available
                </div>
                <Button
                  type={ButtonType.primary}
                  title="Find similar units"
                  className="py-2 px-4 mx-auto uppercase"
                  onClick={() => handleSimilarUnits()}
                />
              </div>
            </div>
          )}

          <InventoryTypeBadge
            inventoryType={item[InventoryField.inventoryType]}
            className={twMerge('z-10 absolute -bottom-3 left-4', sold && 'bg-neutral-500')}
          />
          {!sold && (item[InventoryField.inFlashSale] || item[InventoryField.special]) && (
            <div className="absolute bottom-4 left-4 flex flex-col items-start gap-2">
              {item[InventoryField.special] && <SpecialOfferBadge />}

              {item[InventoryField.inFlashSale] && <FlashSaleBadge />}
            </div>
          )}
          <div className="absolute top-2 left-2 flex items-center gap-1 z-10">
            {!sold && (
              <label
                className={twMerge(
                  'group/favorites bg-white bg-opacity-70 p-2 border-2 leading-none cursor-pointer rounded-full transition-all',
                  isFavorite ? 'border-red-500' : 'border-neutral-700',
                )}
                data-testid="favorite-btn"
              >
                <input type="checkbox" className="hidden" onChange={handleFavorite} checked={isFavorite} />
                <FontAwesomeIcon
                  icon={isFavorite ? faHeartSolid : faHeartOutline}
                  className={twMerge(
                    'w-4 h-4 leading-none',
                    isFavorite ? 'text-red-500' : 'group-hover/favorites:text-red-500',
                  )}
                />
              </label>
            )}
            <FavoriteBadge favorites={item[InventoryField.favorites] || 0} />
          </div>
        </div>
        <div className="relative px-4 py-5 flex flex-col flex-1 bg-white group-hover:bg-blue-50 justify-between">
          <div className="flex flex-col min-h-[4.25rem]">
            <h3 className="font-bold uppercase" data-testid="unit-title">
              {item[InventoryField.title]}
            </h3>
            <div className="flex items-center gap-1 text-neutral-500 mt-1">
              <FontAwesomeIcon icon={faHashtag} className="w-4 h-4" />
              <span className="text-xs" data-testid="unit-stock-number">
                Stock Number: {item[InventoryField.stockNumber]}
              </span>
            </div>
            <div className="flex items-center gap-1 text-neutral-500 mt-1">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                <path
                  fillRule="evenodd"
                  d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-xs" data-testid="unit-location">
                {item[InventoryField.location]}
              </span>
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-between">
            <div className={twMerge('mt-4 flex gap-2 flex-col', sold && 'blur-sm')}>
              {isNewUnit(item) ? <NewPricing item={item} /> : <UsedPricing item={item} />}
            </div>
            <div className="mt-4 border-gray-300/30 flex border-t-2 justify-evenly">
              <div className="flex flex-col mt-4 text-neutral-500 text-xs">
                <span>TYPE</span>
                <span className="font-bold uppercase" data-testid="unit-type">
                  {item[InventoryField.body]} - {item[InventoryField.fuelType]}
                </span>
              </div>
              <figure className="border-l-2 border-gray-300/30 mx-3" />
              <div className="flex flex-col mt-4 text-neutral-500 text-xs">
                <span>SLIDEOUTS</span>
                <span className="font-bold" data-testid="unit-slideouts">
                  {item[InventoryField.slideOutsCount] || 'NONE'}
                </span>
              </div>
            </div>
          </div>
          {sold && <div className="absolute inset-0 bg-white bg-opacity-70" />}
        </div>
      </a>
    </div>
  );
};
