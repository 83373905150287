import { getCldImageUrl, GetCldImageUrlOptions } from 'next-cloudinary';

import { ILayoutContextData } from '@/types/layout';
import { IBasicInventory, IInventory, InventoryField } from '@lamesarv-sdk/types';

export const getDefaultSort = (context: ILayoutContextData, customGeoLocation?: [number, number]) => {
  const sortList = [`${InventoryField.order}:asc`];

  if (customGeoLocation)
    sortList.unshift(`${InventoryField.geoLocation}(${customGeoLocation[0]},${customGeoLocation[1]}):asc`);

  if (context.geoLocation)
    sortList.unshift(`${InventoryField.geoLocation}(${context.geoLocation[0]},${context.geoLocation[1]}):asc`);

  return sortList.slice(0, 2).join(',');
};

export const isNewUnit = (item: { [InventoryField.inventoryType] }) =>
  item[InventoryField.inventoryType].toLowerCase() === 'new';

export const getListPriceLabel = (item: IBasicInventory): string => {
  return isNewUnit(item) ? 'MSRP' : 'Was';
};

export const getUnlockPriceLabel = (item: IInventory) => {
  if (isNewUnit(item)) {
    return {
      buttonLabel: item[InventoryField.isTooLowToShow] ? "UNLOCK TODAY'S PRICE" : "LOCK IN TODAY'S PRICE",
      subtext: item[InventoryField.isTooLowToShow]
        ? 'We’ll get back to you right away.'
        : 'We’ll get back to you right away with today’s best pricing.',
    };
  }

  const listPrice = item[InventoryField.priceList];
  const basePrice = item[InventoryField.price] === listPrice ? undefined : item[InventoryField.price];

  return {
    buttonLabel: !!basePrice ? "LOCK IN TODAY'S PRICE" : "UNLOCK TODAY'S PRICE",
    subtext: !!basePrice
      ? 'We’ll get back to you right away with today’s best pricing.'
      : 'We’ll get back to you right away.',
  };
};

export const getImageUrl = (
  src: string,
  width: number,
  height: number,
  extraOptions?: Partial<GetCldImageUrlOptions>,
) =>
  getCldImageUrl({
    src,
    width,
    height,
    ...(extraOptions || {}),
  });
